var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CardList"
  }, _vm._l(_vm.items, function (item, idx) {
    var _item$image;
    return _c('div', {
      key: idx,
      staticClass: "CardListItem"
    }, [(_item$image = item.image) !== null && _item$image !== void 0 && _item$image.url ? _c('div', {
      staticClass: "CardListItem-header",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClick(item);
          }.apply(null, arguments);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": item.image.url,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "CardListItem-type font-w600"
    }, [_vm._v(_vm._s(item.type))])]) : _vm._e(), _c('div', {
      staticClass: "CardListItem-body"
    }, [_c('h4', {
      staticClass: "CardListItem-title mb-1",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClick(item);
          }.apply(null, arguments);
        }
      }
    }, [_vm._t("title", function () {
      return [_vm._v(_vm._s(item.title))];
    }, {
      "item": item
    })], 2), _c('p', {
      staticClass: "CardListItem-description mb-3 text-muted"
    }, [_vm._t("description", function () {
      return [_vm._v(_vm._s(item.description))];
    }, {
      "item": item
    })], 2), _vm._t("body", null, {
      "item": item
    })], 2)]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }